import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <Router>
      <main>
        <h1>Website offline because Jared Rola is a scammer</h1> {/* Simple message displayed here */}
      </main>
    </Router>
  );
}

export default App;